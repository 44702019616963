.popup-btn {
    white-space: nowrap;
    margin-bottom: 0;
    cursor: pointer;
    border: none !important;
    border-radius: 1rem;
    background: none;
    color: #3699FF;

    &:hover {
        background-color: #F3F6F9;
    }
}

.list-item {
    &.active {
        color: #3699FF !important;
    }
}

.filters-modal .modal-content {
    max-width: 30vw;
    margin: auto;
    max-height: 95vh;

    & > .modal-body {
        padding: 40px;
    }
}

.Filters-Select-Group {
    & > * > .row {
        display: flex;
        flex-direction: column !important;

        & > * {
            margin: 16px 0px;
            color: #464E5F;
            font-size: 13px;
        }
    }
}